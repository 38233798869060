import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import ScrollToTop from "./utils/ScrollToTop";

import Branding from "./Branding";

// import Team from "./Team";

// import Token from "./Token";

import Header from "./Header";
import ExplorerAsAService from "./ExplorerAsAService/ExplorerAsAService";
import Home from "./Home/Home";

import Solutions from "./Solutions/Solutions";
import Technologies from "./Technologies/Technologies";
import About from "./About/About";
import Products from "./Product/Products";
import Footer from "./Footer/Footer";
import WorkShop from "./WorkShop/WorkShop";

export default function Container() {
  return (
    <Router>
      <Header />

      <Switch>
        <Route exact path="/">
          <ScrollToTop>
            <Home />
          </ScrollToTop>
        </Route>

        <Route exact path="/branding">
          <ScrollToTop>
            <Branding />
          </ScrollToTop>
        </Route>
        <Route exact path="/technologies">
          <ScrollToTop>
            <Technologies />
          </ScrollToTop>
        </Route>
        <Route exact path="/products">
          <ScrollToTop>
            <Products />
          </ScrollToTop>
        </Route>
        <Route exact path="/solutions">
          <ScrollToTop>
            <Solutions />
          </ScrollToTop>
        </Route>
        <Route exact path="/solutions/explorer">
          <ScrollToTop>
            <ExplorerAsAService />
          </ScrollToTop>
        </Route>

        <Route exact path="/about">
          <ScrollToTop>
            <About />
          </ScrollToTop>
        </Route>

        {/* <Route exact path="/team">
          <ScrollToTop>
            <Team />
          </ScrollToTop>
        </Route> */}

        <Route exact path="/event/student-tech-horizon">
          <ScrollToTop>
            <WorkShop />
          </ScrollToTop>
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}
